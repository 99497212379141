import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        We are an independent, family run restaurant specialising in exquisite
        Indian cuisine that offers free home delivery.
      </Typography>

      <Typography variant="body1" paragraph>
        We offer a broad range of dishes from Chicken Madras to King Prawn
        Karahi as well as an assortment of lamb, seafood and vegetarian dishes.
        We offer everything you require for the perfect lunch or dinner.
      </Typography>

      <Typography variant="body1" paragraph>
        Our team is passionate about food and our customers. Whether in our
        restaurant dining room or at your door, we give 100% each day to provide
        the best service available in London.
      </Typography>

      <Typography variant="body1" paragraph>
        We are always looking for ways to improve. If you have any comments that
        would help us to meet your needs better then please contact us to share
        your thoughts.
      </Typography>

      <Typography variant="body1" paragraph>
        We look forward to seeing you soon,
      </Typography>

      <Typography variant="body1" paragraph>
        From all of us at Curry Leaf
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
